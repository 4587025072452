<template>
  <section
    id="contact"
  >
 <v-sheet
    :height="$vuetify.breakpoint.smAndUp ? '50vh' : '75vh'"
    color="primary"
    tile
  >
    <!-- <v-img
      :key="image"
      :src="image"
      gradient="to top, rgba(0,0,0, .10), rgba(0,0,0, .50)"
      height="100%"
      position="top"
    > -->
      <v-container
        fill-height
        fluid
        class="py-0"
      >
        <!-- <v-row class="fill-height"> -->
        <v-row>
          <v-col justify="center" align="center" class="ml-4 mt-8">
            <h1
              class="mb-2 white--text big-text text-uppercase">Contact Feil Counseling</h1>
            <p
              class="white--text mb-0" style="font-size: 1.2em">We look forward to partnering with you.</p>
          </v-col>
        </v-row>
      </v-container>
    <!-- </v-img> -->
  </v-sheet>
  <v-container :class="$vuetify.breakpoint.mdAndDown ? '' : 'px-8'">
    <!-- <heading>Contact Us</heading> -->

    <v-container>
      <v-row>
        <v-col
          xs12
          md6
        >
          <v-form>
            <v-container pa-0>
              <v-row>
                <v-col
                  cols="12"
                  class="mb-4 grey--text"
                >
                               <!-- 15 minute consult on the Phone for new people
               returning customers need to have the form -->
               <h2>Free 15 Minute Consult</h2>
               To schedule a 15 minute consultation, please fill out the below form and be sure to include your phone number, along with some times to reach you so we can find the best time to schedule. 
               <br>
               <br>
               <strong>You can also call us at 720-689-4631‬ to setup a time for a consult.</strong>
               <br>
               <br>
               During the Consult we can work out the type of session, like Teletherapy or an In Office Appointment that will work best for your needs.
               <!-- <h2>In Office Appointment</h2>
                  To schedule an In Office appointment, please fill out the below Intake PDF using Adobe Acrobat, or Chromes built-in PDF Viewer. Once filled out, you can save the PDF, and print it. The Intake PDF must be printed, filled out, and brought with you to the appointment.<br><br>
                  <a href="/static/INTAKE-Doug-Feil- FC&C-Fillable.pdf">Intake PDF - Required for Appointment</a>   -->
                  <!-- <h2>Teletherapy</h2>
                  To schedule Teletherapy, please fill out the below contact form, and select the Teletherapy Option. Joyce will reach out to you via email, with a digital form for you to fill out, and help you pick the ideal time for your Teletherapy session. Teletherapy is done using either Google Meet, or Phone Call depending on your preference.
                  <br><br> -->
                </v-col>
                <template v-if="!success">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="First Name*"
                    v-model="forminfo.firstname"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Last Name*"
                    v-model="forminfo.lastname"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Your Email*"
                    v-model="forminfo.email"
                    :rules="[rules.checkemail]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Your Phone Number*"
                    outlined
                    v-model="forminfo.phone"
                    :rules="[rules.checkphone]"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    :block="$vuetify.breakpoint.xsOnly"
                    color="primary"
                    depressed
                    x-large
                    :loading="loading"
                    :disabled="loading || Validation"
                    @click="sendForm()"
                  >
                    Send
                  </v-btn>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8" :class="$vuetify.breakpoint.mdAndDown ? '' : 'pt-0 ml-4'">
                  <v-checkbox class="pt-0 mt-3" style="display: inline-block;" v-model="forminfo.privateinfo" label="By sending your information using this form, you understand that it, in rare cases, might be intercepted by a third party."></v-checkbox>
                </v-col>
                </template>
              </v-row>
              <v-row v-if="success">
                <v-col cols="12">
                  <v-alert type="success">
                    Thank you for your reaching out to us! We will be in touch with you shortly.
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <a href="http://feilcounselingandconsulting.com/inofficeappointment">Trying to Schedule an In Office Appointment?</a>
          <br>
          <a href="http://feilcounselingandconsulting.com/inofficeappointment">Trying to Schedule a Teletherapy Session?</a>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-container
            fluid
            pa-0
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-card-title>Mailing &amp; Office Address</v-card-title>
                <v-card-text>
                  <div>6112 S Devinney Way</div>
                  <div>Littleton, CO 80127</div>
                  <div>United States</div>
                </v-card-text>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-card-title>Contact Info</v-card-title>
                <v-card-text>
                  <div>720-689-4631‬</div>
                  <div>
                    <a href="mailto:dfeil@feilcounselingandconsulting.com">dfeil@feilcounselingandconsulting.com</a>
                  </div>
                  <div>
                    <!-- <a href="#">@alphaconstruction</a> -->
                  </div>
                </v-card-text>
              </v-col>

              <v-col cols="12">
                <iframe
                  src="https://maps.google.com/maps?q=6112%20S%20Devinney%20Way,%20Littleton,%20CO%2080127&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  width="100%"
                  height="400"
                  frameborder="0"
                  style="border:0"
                  allowfullscreen
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    </v-container>
  </section>
</template>

<script>
  export default {
    metaInfo() {
        return {
            title: 'Feil Counseling and Consulting Contact Us',
            titleTemplate: 'Contact | Feil Counseling and Consulting',
            htmlAttrs: {
              lang: 'en-US'
            },
            meta: [
              { charset: 'utf-8' },
              { name: 'description', content: 'We look forward to partnering with you.'},
              {property: 'og:site_name', content: 'Feil Counseling and Consulting'},
              {property: 'og:image', content: '/static/feil_logo_vertical.png'},
              {property: 'twitter:image:src', content: '/static/feil_logo_vertical.png'}
            ]
        };
    },
    data: () => ({
      loaded_title: "",
      loaded_subtitle: "",
      privateinfo: false,
      forminvalid: true,
      loading: false,
      success: false,
      forminfo: {
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
        privateinfo: true
      },
      rules: {
        required: value => value.length > 0 || 'Required.',
        checkphone: value => {
                const pattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
                return pattern.test(value) ? null : value.length == 0 ? null : 'Incorrect Phone Number'
            },
        checkemail: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
            },
      }
    }),
    computed: {
        Validation(){
            let emailpattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            let phonepattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

            if(this.forminfo.firstname.length >0 && this.forminfo.lastname.length >0 && this.forminfo.email.length >0 && this.forminfo.email.match(emailpattern) && this.forminfo.email.length >0 && this.forminfo.phone.match(phonepattern) && this.forminfo.privateinfo){ 
                return false;
            }else{
                return true
            }
        }
    },
    methods:{
      async sendForm(){
        console.log(this.forminfo)
          this.loading = true
         let url = `${this.$apidomain}/schedulerequest`
          console.log(url)

          let response = await this.$http.post(url, {
              body: this.forminfo
          })
          console.log(response.body)
          this.loading = false
          this.success = true
          // this.$ua.trackEvent('Contact', 'Contact form filled out');
          this.$ga.event('Form', 'Filled Contact', 'Form Submit', 1000)
          this.forminfo = {
              email: ' ',
              firstname: ' ',
              lastname: ' ',
              phone: ' ',
              privateinfo: true
            }
          },
      
    },
    components: {
      Heading: () => import('@/components/Heading')
    }
  }
</script>
